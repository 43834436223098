.dx-tabs {
  max-width: 100%; // Zajišťuje zobrazení navigačního panelu pro taby
}

.dx-tab.invalid {
  background-color: var(--danger-deadline);
}

.dx-tab {
  height: 55px;
  padding: 0px 28px;

  .dx-tab-content {
    display: inline-flex;
    box-sizing: border-box;
    padding: 8px;
    font-family: var(--font-family-bold);
    font-size: 16px;
    line-height: 16px;
    color: var(--gray-700);
    text-transform: unset;

    &:hover {
      color: var(--gray-900);
    }
  }

  &.dx-tab-selected {
    .dx-tab-content {
      color: var(--gray-900);
      text-transform: unset;
    }

    &::after {
      width: calc(100% - 72px) !important;
      background-color: var(--primary) !important;
      left: 36px !important;
    }
  }

  &.dx-state-focused {
    background-color: var(--transparent) !important;

    &:not(.dx-tab-selected) {
      color: var(--gray-700);
    }
  }

  &.dx-state-active {
    background-color: var(--transparent) !important;
  }

  // DISABLED
  &.dx-state-disabled {
    .dx-tab-content {
      opacity: 1;
      color: var(--gray-300);
    }
  }

  // DISABLED - END

  // remove click animation
  .dx-inkripple {
    display: none;
  }
}
