.workflow-steps-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.workflow-steps-container .workflow-toolbar {
  margin-top: -10px;
}

.workflow {
  height: 80px;
  padding-left: 80px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  justify-content: space-between;
  overflow-x: auto;
}

.workflow-compact {
  height: auto !important;
  padding-left: 0 !important;
}

.workflow-step {
  position: relative;
}

.workflow-step:last-of-type {
  padding-right: 100px;
}

.workflow-step-no-desc:last-of-type {
  padding-right: 10px !important;
}

.workflow-step-connector {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  min-width: 170px;
  height: 20px;
}

.workflow-step-connector-short {
  min-width: 20px !important;
}

.workflow-step img {
  height: 18px;
  width: 18px;
}

.workflow-step .workflow-step-description {
  display: block;
  position: absolute;
  margin-top: 20px;
  margin-left: 9px; //icon size/2
  transform: translate(-50%);
  width: 200px;
  text-align: center;
  font-size: 12px;
}

.workflow-step-compact .workflow-step-description {
  margin-top: -2px !important;
  margin-left: 20px; //icon size/2
  transform: none !important;
}

.workflow-step-no-desc .workflow-step-description {
  width: 10px !important;
}

.workflow-step-description-bold {
  font-family: var(--font-family-bold);
}

.workflow-step-disabled .workflow-step-description {
  color: var(--gray-100);
}

.workflow-step-connector-solid {
  border-top: 2px solid;
  height: 1px;
  margin: auto 2px auto 2px;
  width: 100%;
}

.workflow-step-connector-dashed {
  border-top: 2px dashed;
  height: 1px;
  margin: auto 2px auto 2px;
  width: 100%;
}

.workflow-step-connector-arrow {
  border-top: 2px solid;
  height: 1px;
  margin: auto 2px auto 2px;
  width: 100%;
}

.workflow-step-connector-arrow:before {
  content: '';
  height: 0;
  width: 0;
  display: block;
  background-color: var(--white);
  border-top: 7px solid var(--white);
  border-bottom: 6px solid var(--white);
  border-right: 9px solid var(--gray-700);
  margin-top: -7px;
}

// arrow and dashed connectors - these are not colored
.workflow-step-connector-disabled .workflow-step-connector-arrow,
.workflow-step-connector-disabled .workflow-step-connector-dashed {
  border-color: var(--gray-100);
}

.workflow-step-connector-disabled .workflow-step-connector-arrow:before {
  border-right-color: var(--gray-100);
}

// solid connectors are colored - according to workflow-state and then by availability
.workflow-state-inprogress .workflow-step-connector:not(.workflow-step-connector-disabled) .workflow-step-connector-solid {
  border-color: var(--info);
}

.workflow-state-inprogress .workflow-step-connector-disabled .workflow-step-connector-solid {
  border-color: rgba(45, 113, 215, 0.33);
}

.workflow-state-fail .workflow-step-connector:not(.workflow-step-connector-disabled) .workflow-step-connector-solid {
  border-color: var(--danger);
}

.workflow-state-fail .workflow-step-connector-disabled .workflow-step-connector-solid {
  border-color: rgba(232, 43, 55, 0.33);
}

.workflow-state-success .workflow-step-connector:not(.workflow-step-connector-disabled) .workflow-step-connector-solid {
  border-color: var(--primary);
}

.workflow-state-success .workflow-step-connector-disabled .workflow-step-connector-solid {
  border-color: rgba(65, 148, 104, 0.33);
}

.workflow-state-suspend .workflow-step-connector:not(.workflow-step-connector-disabled) .workflow-step-connector-solid {
  border-color: var(--warning);
}

.workflow-state-suspend .workflow-step-connector-disabled .workflow-step-connector-solid {
  border-color: rgba(236, 101, 19, 0.33);
}

// done, inprogress, fail steps are colored - according to workflow state and then by availability
.workflow-state-inprogress .workflow-step:not(.workflow-step-disabled) img.workflow-step-done,
.workflow-state-inprogress .workflow-step:not(.workflow-step-disabled) img.workflow-step-skip,
.workflow-state-inprogress .workflow-step:not(.workflow-step-disabled) img.workflow-step-inprogress {
  @extend .svg-icon-info;
}

.workflow-state-inprogress .workflow-step-disabled img.workflow-step-done,
.workflow-state-inprogress .workflow-step-disabled img.workflow-step-skip,
.workflow-state-inprogress .workflow-step-disabled img.workflow-step-inprogress {
  filter: invert(80%) sepia(12%) saturate(1195%) hue-rotate(190deg) brightness(98%) contrast(91%);
}

.workflow-state-inprogress .workflow-step-disabled img.workflow-step-future,
.workflow-state-inprogress .workflow-step-disabled img.workflow-step-fail {
  filter: var(--gray-100-filter);
}

.workflow-state-fail .workflow-step:not(.workflow-step-disabled) img.workflow-step-done,
.workflow-state-fail .workflow-step:not(.workflow-step-disabled) img.workflow-step-skip,
.workflow-state-fail .workflow-step:not(.workflow-step-disabled) img.workflow-step-fail {
  @extend .svg-icon-danger;
}

.workflow-state-fail .workflow-step-disabled img.workflow-step-done,
.workflow-state-fail .workflow-step-disabled img.workflow-step-skip,
.workflow-state-fail .workflow-step-disabled img.workflow-step-fail {
  filter: invert(69%) sepia(80%) saturate(275%) hue-rotate(305deg) brightness(95%) contrast(101%);
}

.workflow-state-fail .workflow-step-disabled img.workflow-step-future {
  filter: var(--gray-100-filter);
}

.workflow-state-success .workflow-step:not(.workflow-step-disabled) img.workflow-step-done,
.workflow-state-success .workflow-step:not(.workflow-step-disabled) img.workflow-step-skip {
  @extend .svg-icon-primary;
}

.workflow-state-success .workflow-step-disabled img.workflow-step-done,
.workflow-state-success .workflow-step-disabled img.workflow-step-skip {
  filter: invert(78%) sepia(6%) saturate(757%) hue-rotate(94deg) brightness(102%) contrast(98%);
}

.workflow-state-suspend .workflow-step:not(.workflow-step-disabled) img.workflow-step-done,
.workflow-state-suspend .workflow-step:not(.workflow-step-disabled) img.workflow-step-skip,
.workflow-state-suspend .workflow-step:not(.workflow-step-disabled) img.workflow-step-suspend {
  @extend .svg-icon-warning;
}

.workflow-state-suspend .workflow-step-disabled img.workflow-step-done,
.workflow-state-suspend .workflow-step-disabled img.workflow-step-skip,
.workflow-state-suspend .workflow-step-disabled img.workflow-step-suspend {
  filter: invert(69%) sepia(80%) saturate(275%) hue-rotate(305deg) brightness(95%) contrast(101%);
}

.workflow-state-suspend .workflow-step-disabled img.workflow-step-future {
  filter: var(--gray-100-filter);
}
