.dx-popup-wrapper.dx-popup-content-p2 {
  & > .dx-overlay-content {
    padding: 0;
    border-radius: 8px;
  }

  .notification-panel-header {
    padding: 0 16px;
    height: 60px !important;

    .dx-button {
      .dx-button-content {
        padding: 6px 0 6px 10px;
      }

      &.btn-tertiary .dx-button-text {
        font-size: 14px;
        font-weight: 700;
        font-family: var(--font-family-bold);
        line-height: 16px;
        color: var(--gray-700);
      }

      .fa-question-circle {
        color: var(--primary);
      }
    }
  }

  .dx-popup-content {
    padding: 0;

    .notification-panel-item {
      height: 65px;
      padding: 0 18px !important;
      border-bottom: 2px solid var(--surface-secondary);

      &:hover {
        background: var(--surface-primary);
      }

      .svg-icon-tertiary {
        height: 20px;
        width: 20px;
      }
    }

    .notification-panel-item-content {
      width: calc(100% - 60px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-family: var(--font-family-bold);
      font-size: 16px;
      line-height: 24px;
    }

    .notification-panel-detail-title {
      min-height: 65px;
      width: 100%;
      padding: 0 18px;
      border-bottom: 2px solid var(--surface-secondary);

      .notification-panel-detail-title-content {
        width: calc(100% - 60px);
        font-family: var(--font-family-bold);
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .notification-panel-detail-content {
      .white-space-line {
        overflow: hidden;
        white-space: pre-line;
      }
    }
  }

  .notification-panel-footer {
    border-top: 1px solid var(--surface-secondary);
    height: 56px;
    font-family: var(--font-family-bold);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--gray-700);

    img {
      width: 22px;
    }
  }
}

//.dx-popup-title {
//  &.notification-panel-header {
//    height: 60px !important;
//  }
//}
//
//pps-notification-panel {
//  .badge {
//    min-width: 27px;
//    left: 50%;
//    top: -5px !important;
//  }
//}
