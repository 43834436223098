.text-nowrap {
  white-space: nowrap;
}

.font-bold {
  font-family: var(--font-family-bold);
}

.font-light {
  font-family: var(--font-family-light);
}

.font-regular {
  font-family: var(--font-family-regular);
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary);
}
