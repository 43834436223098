:root {
  @each $color, $value in $colors {
    --#{"" + $color}: #{map-get($value, color)};
    --#{"" + $color}-filter: #{map-get($value, filter)};
    --transparent: transparent;
  }

  @each $font, $value in $fontFamily {
    --font-family-#{$font}: #{$value};
  }

  --header-height-phone: 55px;
  --header-height-tablet: 55px;
  --header-height-desktop: 65px;

  --release-info-label-top: 0px;
  --border-color-light: var(--surface-secondary);
  --body-backgroud: var(--white);
}

[hidden] {
  display: none !important;
}
