@each $color, $value in $svg-icon-color {
  .svg-icon-#{'' + $color} {
    filter: #{$value};
  }
}

@each $size, $value in $svg-icon-size {
  .svg-icon-#{$size} {
    width: map-get($value, width);
    height: map-get($value, height);
  }
}
