@mixin badge {
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  border-radius: 50px;
  min-width: 24px;
  box-sizing: border-box;
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
}

@each $badge, $value in $badges {
  .badge-#{'' + $badge} {
    @include badge;
    background-color: map-get($value, background-color);
    color: map-get($value, color);
  }
}
