#login_wrapper {
  width: 100vw;
  height: 100vh;
  min-width: 400px !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .version {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px;
  }

  .control-bg {
    width: 100%;
    height: 100%;
    background-color: var(--secondary);
    box-shadow: 0 0 15px rgb(0 0 0 / 50%);
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    font-family: var(--font-family-regular);
  }

  .control-wrapper {
    width: 100%;
    padding: var(--mdc-layout-grid-margin-tablet);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .scene {
    background: url('/assets/media/bg/background-auth-page.png') no-repeat;
    background-color: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    background-size: cover !important;
    background-position: center center !important;
    width: 100%;
    height: 100%;
  }

  .control-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    margin-top: 30px;
  }

  .control-header-row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .control-footer {
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--gray-800);
  }

  .logo {
    height: 75px;
    width: 80px;
  }

  .main-logo {
    position: fixed;
    z-index: 1;
    width: 145px;
    height: 58px;
    top: 43px;
    left: 33px;
  }

  .message {
    letter-spacing: 0.2px;
    font-size: 17px;
    line-height: 19px;
  }

  .control {
    text-align: center;
    width: 385px;
  }

  .languageSelector {
    z-index: 10;
    position: absolute;
    right: 0;
    top: 0;
  }

  .authentication-error {
    border-radius: 5px;
    font-size: 17px;
    line-height: 19px;
    letter-spacing: 0.2px;
  }

  .link {
    color: var(--gray-800);
    margin-left: 10px;
  }

  .help {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .delimiter-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--gray-600);
    font-weight: 400;
    font-size: 17px;
    width: 100%;
  }

  .line {
    flex: 1;
    border-bottom: 1px solid var(--gray-500);
  }

  #buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btnLogin,
    .btnRegister {
      width: 120px;
      height: 36px;
      max-width: 100%;
    }
    .btn-primary {
      border: 1px solid var(--gray-900);
    }
  }
}

@include media-breakpoint-up(desktop) {
  #login_wrapper {
    .control-bg {
      width: 500px;
      height: unset;
      min-height: 479px;

      .control-wrapper {
        padding: var(--mdc-layout-grid-margin-desktop);
      }
    }

    .control-header {
      margin-top: 0px;
    }

    .control {
      width: 100%;
    }
  }
}
