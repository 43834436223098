// BUTTONS
$button-icon-height-desktop: 32px;
$button-icon-width-desktop: 32px;
$button-icon-icon-width: 20px;
$button-icon-icon-height: 20px;
$button-height-desktop: 32px;
$dropdown-button-content-height-desktop: 28px;
$button-icon-min-width-desktop: 24px;
$button-icon-width: 20px;
$button-icon-height: 20px;
$button-border-radius: 50px;
$button-font-family: var(font-family-bold);
$button-font-weight: 700;
$button-padding: 8px 16px;
$button-letter-spacing: 0.75px;
$button-border-width: 2px;
$button-font-size: 14px;
$button-line-height: 16px;

.dx-dropdownbutton,
.dx-button {
  height: $button-height-desktop;
  min-width: $button-icon-min-width-desktop !important;
  border-radius: $button-border-radius;
  box-shadow: unset !important;
  border-width: $button-border-width;
  border-style: solid;
  font-family: $button-font-family;
  opacity: unset;
  font-size: $button-font-size;
  line-height: $button-line-height;

  .dx-button-mode-outlined {
    height: $dropdown-button-content-height-desktop !important;
    border-radius: $button-border-radius !important;
    border: none;
  }

  &.dx-button-has-text {
    .dx-button-content {
      padding: $button-padding;
    }

    .dx-button-text {
      opacity: unset;
      font-family: $button-font-family;
      font-weight: $button-font-weight;
      letter-spacing: $button-letter-spacing;
      text-transform: unset;
    }
  }

  .dx-icon {
    opacity: unset;
    width: $button-icon-width !important;
    height: $button-icon-height !important;
  }

  .dx-inkripple {
    display: none !important;
  }

  &.dx-has-icon:not(.dx-button-has-text) {
    height: $button-icon-height-desktop !important;
    width: $button-icon-width-desktop !important;

    .dx-icon {
      border-radius: 50%;
      width: $button-icon-icon-width !important;
      height: $button-icon-icon-height !important;
    }
  }
}

@each $button, $value in $buttons {
  .dx-dropdownbutton,
  .dx-button {
    $defaultValue: map-get($value, default);
    $focusedValue: map-get($value, focused);
    $hoverValue: map-get($value, hover);
    $disabledValue: map-get($value, disabled);

    &.btn-#{$button} {
      background: map-get($defaultValue, background-color);
      border-color: map-get($defaultValue, border-color);
      color: map-get($defaultValue, color);

      .dx-button-text {
        color: map-get($defaultValue, color);
      }

      .dx-icon {
        color: map-get($defaultValue, color);
      }

      &:not(.btn-icon-colorful) img.dx-icon {
        filter: map-get($defaultValue, svg-color-filter);
      }

      &.dx-state-focused {
        background: map-get($defaultValue, background-color);
        border-color: map-get($defaultValue, border-color);
        color: map-get($defaultValue, color);

        .dx-icon {
          color: map-get($defaultValue, color);
        }

        &:not(.btn-icon-colorful) img.dx-icon {
          filter: map-get($defaultValue, svg-color-filter);
        }

        .dx-button-text {
          color: map-get($defaultValue, color);
        }
      }

      &.dx-state-active {
        background: map-get($defaultValue, background-color);
        border-color: map-get($defaultValue, border-color);
        color: map-get($defaultValue, color);

        .dx-icon {
          color: map-get($defaultValue, color);
        }

        &:not(.btn-icon-colorful) img.dx-icon {
          filter: map-get($defaultValue, svg-color-filter);
        }

        .dx-button-text {
          color: map-get($defaultValue, color);
        }
      }

      &.dx-state-disabled {
        background: map-get($disabledValue, background-color);
        border-color: map-get($disabledValue, border-color);
        color: map-get($disabledValue, color);
        opacity: unset;

        .dx-button-content {
          opacity: 1 !important;
        }

        .dx-icon {
          color: map-get($disabledValue, color);
        }

        &:not(.btn-icon-colorful) img.dx-icon {
          filter: map-get($disabledValue, svg-color-filter);
        }

        .dx-button-text {
          color: map-get($disabledValue, color);
        }
      }

      &.dx-state-hover {
        background: map-get($hoverValue, background-color);
        border-color: map-get($hoverValue, border-color);
        color: map-get($hoverValue, color);

        .dx-icon {
          color: map-get($hoverValue, color);
        }

        &:not(.btn-icon-colorful) img.dx-icon {
          filter: map-get($hoverValue, svg-color-filter);
        }

        .dx-button-text {
          color: map-get($hoverValue, color);
        }
      }

      &.dx-button-has-icon:not(.dx-button-has-text) {
        .dx-button-content {
          padding: 0;
        }

        height: $button-icon-height-desktop !important;
        width: $button-icon-width-desktop !important;

        .dx-icon {
          border-radius: 50%;
          width: $button-icon-icon-width !important;
          height: $button-icon-icon-height !important;
        }
      }
    }
  }
}

.dx-dropdownbutton,
.dx-button {
  @each $color, $value in $svg-icon-color {
    &.btn-icon-#{'' + $color} {
      &:not(.btn-icon-colorful) {
        img.dx-icon {
          filter: #{$value} !important;
        }
      }

      height: $button-icon-height-desktop !important;
      width: $button-icon-width-desktop !important;

      .dx-button-content {
        padding: 0 !important;
      }

      .dx-icon {
        border-radius: 50%;
        width: $button-icon-icon-width !important;
        height: $button-icon-icon-height !important;
      }

      $btnIconValue: map-get($buttons, tertiary);
      $btnIconDefaultValue: map-get($btnIconValue, default);
      $btnIconHoverValue: map-get($btnIconValue, hover);
      $btnIconDisabledValue: map-get($btnIconValue, disabled);

      color: map-get($btnIconDefaultValue, color);
      background: map-get($btnIconDefaultValue, background-color);
      border-color: map-get($btnIconDefaultValue, border-color);

      &.dx-state-focused {
        background: map-get($btnIconDefaultValue, background-color);
        border-color: map-get($btnIconDefaultValue, border-color);
        color: map-get($btnIconDefaultValue, color);
      }

      &.dx-state-active {
        background: map-get($btnIconDefaultValue, background-color);
        border-color: map-get($btnIconDefaultValue, border-color);
        color: map-get($btnIconDefaultValue, color);
      }

      &.dx-state-disabled {
        background: map-get($btnIconDisabledValue, background-color);
        border-color: map-get($btnIconDisabledValue, border-color);
        color: map-get($btnIconDisabledValue, color);
        opacity: unset;

        &:not(.btn-icon-colorful) img.dx-icon {
          filter: map-get($btnIconDisabledValue, svg-color-filter);
        }
      }

      &.dx-state-hover {
        background: map-get($btnIconHoverValue, background-color);
        border-color: map-get($btnIconHoverValue, border-color);
      }
    }
  }
}
