.material-sheet-state-wrapper {
  display: flex;
  height: $workflow-state-info-height;
  min-width: 41px;
  gap: 4px;
  box-sizing: border-box;

  .material-sheet-state {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 3px;
    border-radius: 2px;

    .material-sheet-state-icon {
      width: $workflow-state-info-icon-size;
      height: $workflow-state-info-icon-size;
      filter: $workflow-state-info-icon-svg-filter;
    }
  }

  .step-label {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: $workflow-state-info-font-family;
    font-weight: 900;
    font-size: $workflow-state-info-font-size;
    letter-spacing: 3px;
    height: 20px;
    margin: 0 1px 0 4px;
    line-height: $workflow-state-info-font-size;
  }

  @each $step, $value in $workflow-state-info-steps {
    .#{$step}-step {
      color: map-get($value, color);
      background-color: map-get($value, background-color);
    }
  }
}
