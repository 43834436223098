@include media-breakpoint-only(mobile) {
  .document-types-devexpress-grid {
    height: 500px;
  }
}

@include media-breakpoint-only(tablet) {
  .document-types-devexpress-grid {
    height: 500px;
  }
}

@include media-breakpoint-only(desktop) {
  .document-types-devexpress-grid {
    height: calc(
      100vh - #{$header-height-desktop} - var(--mdc-layout-grid-margin-desktop) * 2 - #{$environment-info-top-label-height} -
        #{$footer-height} - 305px
    );
  }
}
