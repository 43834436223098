:host {
  height: $enum-info-height !important;
}

.enum-info {
  align-items: center;
  display: inline-flex;
  height: $enum-info-height !important;
  border-radius: 2px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: $enum-info-font-family;
  font-size: $enum-info-font-size;
  font-weight: 900;
  box-sizing: border-box;

  &__normal {
    padding: 5px 10px;
    gap: 10px;
  }

  &__compact {
    padding: 1px 7px 1px 3px;
    gap: 4px;
  }

  &__icon {
    position: relative;
  }
}

@each $class-name, $value in $enum-info-text-customization {
  .enum-info-text-#{'' + $class-name} {
    color: map-get($value, color);
  }
}

@each $class-name, $value in $enum-info-background-customization {
  .enum-info-background-#{'' + $class-name} {
    background-color: map-get($value, background-color);
  }
}

@each $class-name, $value in $enum-info-icon-customization {
  .enum-info-icon-#{'' + $class-name} {
    img {
      filter: map-get($value, filter);
    }
  }
}
